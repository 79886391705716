<template>
    <div class="wrap">
        <map-google class="pc-map"></map-google>
        <map-googlewap class="wap-map"></map-googlewap>
    </div>
</template>
<script>
import mapGoogle from '@/components/other/mapgoogle'
import mapGooglewap from '@/components/other/mapgooglewap'
export default {
    name:'',
    components: {
        mapGoogle,
        mapGooglewap,
    },
    data(){
        return{
            // shopList:[
            //     {
            //         "id": 8,
            //         "address": "天津市西青区",
            //         "cn_name": "天津店铺",
            //         "en_name": "TJ",
            //         "it_name": "TJ",
            //         "lat": -33.91721,
            //         "lng":  151.2263,
            //         "business_day_start": "周一",
            //         "business_day_end": "周日",
            //         "business_time_start": "10:38:26",
            //         "business_time_end": "10:38:28",
            //         "create_time": "2022-07-19 10:38:49",
            //         "sort": 6,
            //         "status": 1,
            //         "delete_time": 0,
            //         "telno": "022-222954461",
            //         "update_time": "2022-07-19 10:39:21"
            //     },
            //     {
            //         "id": 4,
            //         "address": "南开区鑫茂科技园1号楼，1809， 天津市， 中国",
            //         "cn_name": "巴黎2区门店",
            //         "en_name": "Paris zone 2 store",
            //         "it_name": "Punto vendita Parigi zona 2",
            //         "lat": -33.91539,
            //         "lng": 151.2282,
            //         "business_day_start": "",
            //         "business_day_end": "",
            //         "business_time_start": "08:00",
            //         "business_time_end": "22:00",
            //         "create_time": "2022-06-03 18:26:29",
            //         "sort": 2,
            //         "status": 1,
            //         "delete_time": 0,
            //         "telno": "023999222,012222333",
            //         "update_time": "2022-06-12 09:59:03"
            //     },
            //     {
            //         "id": 3,
            //         "address": "南开区鑫茂科技园1号楼B座，1809， 天津市， 中国",
            //         "cn_name": "巴黎1区门店",
            //         "en_name": "Paris 1 store",
            //         "it_name": "Parigi 1 negozio",
            //         "lat": -33.91747, 
            //         "lng": 151.22912,
            //         "business_day_start": "",
            //         "business_day_end": "",
            //         "business_time_start": "06:00",
            //         "business_time_end": "22:00",
            //         "create_time": "2022-06-03 18:23:20",
            //         "sort": 0,
            //         "status": 1,
            //         "delete_time": null,
            //         "telno": "10223421,02030303",
            //         "update_time": "1970-01-01 08:00:00"
            //     },
            //     {
            //         "id": 5,
            //         "address": "南开区鑫茂科技园1号楼，1809， 天津市， 中国",
            //         "cn_name": "巴黎3区门店",
            //         "en_name": "Paris zone 3 store",
            //         "it_name": "Punto vendita Parigi zona 3",
            //         "lat": -33.9191, 
            //         "lng": 151.22907,
            //         "business_day_start": "",
            //         "business_day_end": "",
            //         "business_time_start": "09:00",
            //         "business_time_end": "22:00",
            //         "create_time": "2022-06-03 18:48:41",
            //         "sort": 0,
            //         "status": 1,
            //         "delete_time": null,
            //         "telno": "02930000,02381232",
            //         "update_time": "1970-01-01 08:00:00"
            //     },
            //     {
            //         "id": 6,
            //         "address": "Via Giovanni Battista Niccolini, 33，milano",
            //         "cn_name": "仓库店铺",
            //         "en_name": "cangkudianpuyingwen",
            //         "it_name": "Negozio italiano",
            //         "lat": -33.91725,
            //         "lng": 151.23011,
            //         "business_day_start": "周六",
            //         "business_day_end": "周三",
            //         "business_time_start": "02:00",
            //         "business_time_end": "23:00",
            //         "create_time": "2022-07-13 10:07:21",
            //         "sort": 0,
            //         "status": 1,
            //         "delete_time": null,
            //         "telno": "1234897",
            //         "update_time": "1970-01-01 08:00:00"
            //     },
            //     {
            //         "id": 7,
            //         "address": "天津市天津站",
            //         "cn_name": "中文店铺",
            //         "en_name": "enshop",
            //         "it_name": "it",
            //         "lat": -33.91872,
            //         "lng": 151.23089,
            //         "business_day_start": "周二",
            //         "business_day_end": "周五",
            //         "business_time_start": "00:00",
            //         "business_time_end": "00:00",
            //         "create_time": "2022-07-18 08:49:20",
            //         "sort": 0,
            //         "status": 1,
            //         "delete_time": null,
            //         "telno": "111",
            //         "update_time": "1970-01-01 08:00:00"
            //     },
            //     {
            //         "id": 9,
            //         "address": "Strade italiane",
            //         "cn_name": "魁首店铺",
            //         "en_name": "Leader shop",
            //         "it_name": "Negozio leader",
            //         "lat": -33.91784,
            //         "lng": 151.23094,
            //         "business_day_start": "周二",
            //         "business_day_end": "周日",
            //         "business_time_start": "11:36",
            //         "business_time_end": "23:00",
            //         "create_time": "2022-07-19 11:35:05",
            //         "sort": 0,
            //         "status": 1,
            //         "delete_time": null,
            //         "telno": "17622818248",
            //         "update_time": "1970-01-01 08:00:00"
            //     }
            // ],
            
            
        }
    },
    created(){
    }
}
</script>
<style lang="scss" scoped>

/deep/.modal-content{
    min-width: 400px;
}
/deep/.bg-info{
    background: #ffffff !important;  
}
/deep/.navbar-dark .navbar-nav .nav-link{
    color: #2E2E2E !important;  
}
/deep/.navbar-dark .navbar-brand{
    color: #2E2E2E !important;  
}
/deep/.navbar-dark .navbar-toggler{
    color:  #2E2E2E !important;  
    border-color: #2E2E2E !important;  
}
// 模态窗样式调整设计图模样
/deep/.modal-content{
    min-width: 200px;
}
/deep/.modal-content{
    border-radius:20px;
}
/deep/.modal-content{
    padding:0 25px;
}
/deep/.modal-header{
    padding-top:25px;
    padding-bottom:20px;
    padding-left:0px;
    padding-right:0px;
}
/deep/.modal-body{
    padding-left:0px;
    padding-right:0px;
}
.wrap{
    height: 100%;
    width: 100%;
}
.pc-banner{
    height:10%;
    background:#2E2E2E;
    .pc-nav-container{
        width: 100%;
        padding:0;
        font-size:12px;
        .row{
            width: 100%;
            display: -webkit-flex;
            -webkit-align-items: center; 
            display: flex;
            align-items: center;
            padding:10px 0;
            margin: 0;
            img{
                height:110px;
                margin: 0 auto;
            }
            .nav-group{
                width: 100%;
                display: -webkit-flex;
                -webkit-align-items: center; 
                display: flex;
                align-items: center;
                .nav-group-box{
                    display: inline-block;
                    margin: 0 auto;
                    padding:5px 20px;
                    text-align: center;
                    border:1px solid #ffffff;
                    
                    color: #ffffff;
                    .stopicon{
                        display: inline-block;
                        
                        height:24px;
                        width:24px;
                        background: url(~@/assets/images/stop_img.png) no-repeat left top;
                        background-size: 100% 100%;
                        margin-right: 15px;
                        vertical-align: middle;
                        color: #ffffff;
                    }
                    .txtspe{
                        display: inline-block;
                        font-size:12px;
                        margin-left:15px;
                        color : #ffffff;
                    }
                    .cnstyles{
                        letter-spacing:8px;
                    }
                }
            }
            .nav-group-txt{
                width: 100%;
                font-size:12px;
                color: #ffffff;
                text-align: center;
                padding:10px 0 0;
            }
            .cnstyle{
                letter-spacing:30px;
                padding-left:20px;
            }
        }
    }
}
.pc-content{
    height:90%;
    display: flex;
    -webkit-box-orient: horizontal;
    .pc-left_cen{
        width: 20%;
        overflow-y: auto;
        background: #fff;
        .dortitle{
            font-size:16px;
            padding:20px 0;
            text-align: center;
        }
        .dorlist{
            .dorlist-item{
                display: flex;
                -webkit-box-orient: horizontal;
                padding:0 10px 10px;
                .dor-air{
                    width:10%;
                    padding-top:5px;
                    text-align: center;
                    .icon_air{
                        display: inline-block;
                        width: 20px;
                        height:20px;
                        background: url(~@/assets/images/icon_dw_air.png) no-repeat left top;
                        background-size:100% 100%;
                    }
                }
                .dor-txtbox{
                    width:80%;
                    .dor_txtbox_title{
                        font-size:20px;
                        padding:0 0 10px 0;
                    }
                    .dor_txtbox_txt{
                        color:#9a9a9a;
                        padding:2px 0;
                    }
                }
                .dor-map{
                    width:10%;
                    padding-top:10px;
                    text-align: center;
                    cursor: pointer;
                    .icon_map{
                        display: inline-block;
                        width: 30px;
                        height:30px;
                        background: url(~@/assets/images/icon_dw.png) no-repeat left top;
                        background-size:100% 100%;
                    }
                }
            }
        }
    }
    .pc-right_cen{
        width: 80%;
        position: relative;
        .pc-fixed-langage{
            display: flex;
            -webkit-box-orient: horizontal;
            position: absolute;
            right:10px;
            top:10px;
            z-index: 10;
            height:50px;
            line-height:50px;
            background: #222;
            width:400px;
            border-radius:4px;
            padding:0 10px;
            color: #fff;
            .pc-langage-title{
                color: #fff;
                
                width:55%;
                padding:0 10px;
            }
            .pc-langage-txt{
                width:45%;
                color: #fff;
                text-align: right;
                cursor: pointer;
                span{
                    color: #fff;
                }
                .el-icon-arrow-down{
                    color: #fff;
                    margin-left: 5px;
                }
            }
        }
    }
}
.wap-banner{
    // height:12%;
    padding:10px 0;
    width:100%;
    background:#2E2E2E;
    .wap-banner-container{
        width: 100%;
        padding:0;
        font-size:12px;
        .row{
            width: 100%;
            display: -webkit-flex;
            -webkit-align-items: center; 
            display: flex;
            align-items: center;
            padding:0 0;
            margin: 0;
            img{
                height:100px;
                margin: 0 auto;
            }
            .nav-group{
                width: 100%;
                display: -webkit-flex;
                -webkit-align-items: center; 
                display: flex;
                align-items: center;
                padding:0 5px;
                .nav-group-box{
                    display: inline-block;
                    margin: 0 auto;
                    padding:5px ;
                    text-align: center;
                    border:1px solid #ffffff;
                    
                    color: #ffffff;
                    .stopicon{
                        display: inline-block;
                        height: 20px;
                        width:20px;
                        background: url(~@/assets/images/stop_img.png) no-repeat left top;
                        background-size: 100% 100%;
                        margin-right: 15px;
                        vertical-align: middle;
                        color: #ffffff;
                    }
                    .txtspe{
                        display: inline-block;
                        
                        margin-left:15px;
                        color : #ffffff;
                    }
                    .cnstyles{
                        letter-spacing:8px;
                    }
                }
            }
            .nav-group-txt{
                width: 100%;
                
                color: #ffffff;
                text-align: center;
                padding:10px 0 0;
            }
            .cnstyle{
                letter-spacing:30px;
                padding-left:20px;
            }
        }
    }
}
.wap-shop-map{
    height:48%;
    background: pink;
    position: relative;
    .pc-fixed-langage{
        display: flex;
        -webkit-box-orient: horizontal;
        position: absolute;
        right:auto;
        top:10px;
        z-index: 100;
        // height:40px;
        // line-height:40px;
        background: #222;
        width:90%;
        margin:0 5%;
        border-radius:4px;
        padding: 10px;
        color: #fff;
        .pc-langage-title{
            color: #fff;
            
            width:55%;
            padding:0 10px;
        }
        .pc-langage-txt{
            width:45%;
            color: #fff;
            text-align: right;
            cursor: pointer;
            
            span{
                color: #fff;
            }
            .el-icon-arrow-down{
                color: #fff;
                margin-left: 5px;
            }
        }
    }
}
.wap-shop-list{
    height:40%;
    overflow-y: auto;
    background: #fff;
    .dorlist{
        .dorlist-item{
            display: flex;
            -webkit-box-orient: horizontal;
            padding:0 10px 10px;
            .dor-air{
                width:10%;
                text-align: center;
                padding-top:5px;
                .icon_air{
                    display: inline-block;
                    width: 20px;
                    height:20px;
                    background: url(~@/assets/images/icon_dw_air.png) no-repeat left top;
                    background-size:100% 100%;
                }
            }
            .dor-txtbox{
                width:80%;
                .dor_txtbox_title{
                    font-size:20px;
                    padding:0 0 10px 0;
                }
                .dor_txtbox_txt{
                    color:#9a9a9a;
                    padding:2px 0;
                }
            }
            .dor-map{
                width:10%;
                padding-top:10px;
                text-align: center;
                .icon_map{
                    display: inline-block;
                    width: 30px;
                    height:30px;
                    background: url(~@/assets/images/icon_dw.png) no-repeat left top;
                    background-size:100% 100%;
                }
            }
        }
    }
}

.language-item{
    padding:10px 0;
    
    position: relative;
    cursor: pointer;
    .nationalFlag{
        display: inline-block;
        width:30px;
        height:20px;
        vertical-align: middle;
        margin-top: -4px;
        margin-right: 8px;
        img{
            width:30px;
            height:20px;
        }
    }
    .italy{
        background:url(~@/assets/images/icon_italy.png) no-repeat left top;
        background-size: 100% 100%;
    }
    .uk{
        background:url(~@/assets/images/icon_uk.png) no-repeat left top;
        background-size: 100% 100%;
    }
    .china{
        background:url(~@/assets/images/icon_china.png) no-repeat left top;
        background-size: 100% 100%;
    }
    .iconCheckFlag{
        position: absolute;
        right:0;
        top:10px;
        display: inline-block;
        color: #1f73b7;
        text-align: right;
        .el-icon-check{
            font-size:20px;
            font-weight: bold;
            color: #1f73b7;
        }
    }
}

@media screen and (min-width: 1200px) {
    .wrap{
        .wap-banner{display: none;}
        .wap-shop-map{display: none;}
        .wap-shop-list{display: none;}
        .wap-map{display: none;}
    }
}
@media screen and(min-width: 960px) and (max-width: 1199px) {
    .wrap{
        .wap-banner{display: none;}
        .wap-shop-map{display: none;}
        .wap-shop-list{display: none;}
        .wap-map{display: none;}
       
    }
}
@media screen and(min-width: 768px) and (max-width: 959px) {
    .wrap{
        .pc-content{
            .pc-left_cen{
                width: 30%;
            }
            .pc-right_cen{
                width: 70%;
            }
        }
        .wap-banner{display: none;}
        .wap-shop-map{display: none;}
        .wap-shop-list{display: none;}
        .wap-map{display: none;}
    }
}
@media screen and(min-width: 480px) and (max-width: 767px) {
    .wrap{
        .pc-banner{display: none;}
        .pc-content{display: none;}
        .pc-map{display: none;}
    }
}
@media screen and (max-width: 479px) {
    .wrap{
        .pc-banner{display: none;}
        .pc-content{display: none;}
        .pc-map{display: none;}
    }
}
</style>